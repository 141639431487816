var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-component", {
    attrs: {
      itemsRepo: _vm.usersRepo,
      fields: _vm.fields,
      isDataFromModel: false,
      isAddEditSidbar: false,
      sidebarData: _vm.selectedRow,
      copyData: _vm.selectedCopy,
      getByIdOnEditFilter: function () {
        return false
      },
      addEditLink: {
        name: "update-users",
        params: {
          user: _vm.selectedCopy.id ? _vm.selectedCopy.id.toString() : "new",
        },
      },
      deleteFilter: function () {
        return false
      },
    },
    on: {
      "update:sidebarData": function ($event) {
        _vm.selectedRow = $event
      },
      "update:sidebar-data": function ($event) {
        _vm.selectedRow = $event
      },
      "update:copyData": function ($event) {
        _vm.selectedCopy = $event
      },
      "update:copy-data": function ($event) {
        _vm.selectedCopy = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "cell(name)",
        fn: function (data) {
          return [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "3" } },
                  [
                    _c("b-avatar", {
                      staticClass: "float-left",
                      attrs: {
                        variant: _vm.getColor(),
                        text: _vm.getName(
                          data.item.firstName + " " + data.item.lastName
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c("b-col", [
                  _vm._v("\n        " + _vm._s(data.item.name) + "\n        "),
                  _c("br"),
                  _c("span", { staticClass: "grey-text smaller-text" }, [
                    _vm._v("ID: " + _vm._s(data.item.id)),
                  ]),
                ]),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "cell(emailAddress)",
        fn: function (data) {
          return [_vm._v("\n    " + _vm._s(data.item.email) + "\n  ")]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }